import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, HStack, useBreakpointValue, useColorModeValue, Button } from '@chakra-ui/react'
import { AiOutlineSearch } from 'react-icons/ai'

import ProfileDropdown from './ProfileDropdown'

import { useAuth } from 'contexts'

import { Input } from 'components'

const Topbar = () => {
  const { role } = useAuth()
  const [search, setSearch] = useState()
  const navigate = useNavigate()

  const isDesktop = useBreakpointValue({
    base: false,
    md: false,
    lg: true
  })

  const goSearch = () => {
    if (!search?.length) return
    navigate(`/admin/search/${encodeURIComponent(search)}`)
    setSearch('')
  }

  return (
    <Box boxShadow={useColorModeValue('sm', 'sm-dark')} className="app_header" py={{ base: '3', lg: '4' }}>
      <HStack ml={'2rem'}>
        {isDesktop && role === 'admin' && (
          <Box width='25rem' display='flex' style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Input value={search} setValue={setSearch} name="Rechercher..." />
            <Button ml='3' data-variant='outline' onClick={goSearch}><AiOutlineSearch /></Button>
          </Box>
        )}
      </HStack>
      {isDesktop && <HStack spacing="4" marginRight={'3rem'}>
        <ProfileDropdown />
      </HStack>}
    </Box>
  )
}

export default Topbar
