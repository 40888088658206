import { useEffect, useState } from 'react'
import { AddIcon, SmallAddIcon, WarningTwoIcon } from '@chakra-ui/icons'
import { TabPanel, Text, Button, HStack, Table, Thead, Tr, Th, Tbody, Td, TableContainer, Box, Icon, useToast, Checkbox, CheckboxGroup } from '@chakra-ui/react'
import { Card, Input } from 'components'
import { CiImport, CiExport } from 'react-icons/ci'
import { FiEdit2, FiTrash2 } from 'react-icons/fi'
import AddCategory from '../modals/AddCategory'
import { useAuth, useDatas } from 'contexts'
import AddTask from '../modals/AddTask'
import EditTask from '../modals/EditTask'
import ConfirmDelete from '../../../../../contexts/modals/components/confirmDelete'
import HistoricalModal from '../modals/HistoricalModal'

const getStatusColors = (status) => {
  let statusColors
  if (typeof status === 'number') {
    statusColors = {
      0: '#FCC35B',
      1: '#1BC181'
    }
  } else {
    statusColors = {
      false: '#FCC35B',
      true: '#1BC181'
    }
  }
  return statusColors[status] ?? '#DE4433'
}

const Interventions = ({ chantier }) => {
  const { callApi } = useAuth()
  const { datas: { measures } } = useDatas()

  const toast = useToast()
  /* Ouverture des modales d'edition/suppression/création de tâches/categories */
  const [modalOpen, setModalOpen] = useState(false)
  const [modalOpenCategory, setModalOpenCategory] = useState(false)
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false)
  const [modalHistoricalOpen, setOpenModalHistorical] = useState(false)

  /* Création des options pour les selects utilisés dans les différentes modales */
  const [categoriesOptions, setCategoriesOptions] = useState()
  const [tasksOptions, setTasksOptions] = useState()

  /* Recherche sur les tableaux de recherche  */
  const [searchQuery, setSearchQuery] = useState('')
  const [searchInput, setSearchInput] = useState('')

  // const [measures, setMeasures] = useState()
  const [categories, setCategory] = useState()
  const [tasksByCategories, setTasksByCategories] = useState()
  const [tasks, setTasks] = useState()

  const [rows, setRows] = useState()
  const [toogleShow, setShowToogle] = useState([])
  const [categorySel, setCategorySel] = useState()
  const [taskSelected, setTaskSelected] = useState([])
  const [removeVentilation, setRemoveVentilation] = useState(false)

  useEffect(() => {
    getTasks()
    getTasksByCat()
    getCategories()
  }, [])

  useEffect(() => {
    if (categories) {
      const categoriesList = (categories.map((category) => {
        return ({ value: category.id, label: category.title })
      }))
      setCategoriesOptions(categoriesList)
    }

    if (tasks) {
      const tasksList = (tasks.map((task) => {
        return ({ value: task.id, label: task.label })
      }))
      setTasksOptions(tasksList)
    }
  }, [tasks, categories, measures])

  useEffect(() => {
    if (searchQuery === '') getTasksByCat()

    const filteredRows = tasksByCategories?.map((cat) => {
      const newTask = {
        id: cat.id,
        title: cat.title,
        tasks: []
      }
      const data = cat.tasks.data.filter((task) => (
        task.in_progress === 1 ? task : task?.label?.toLowerCase().includes(searchQuery.toLowerCase()) ? task : ''
      ))
      newTask.tasks.data = data
      return newTask
    })
    setTasksByCategories(filteredRows)
  }, [searchQuery])

  useEffect(() => {
    if (searchInput === '') getTasksByCat()

    const filteredRows = tasksByCategories?.map((cat) => {
      const newTask = {
        id: cat.id,
        title: cat.title,
        tasks: []
      }
      const data = cat.tasks.data.filter((task) => (
        task.in_progress === 0 ? task : task?.label.toLowerCase().includes(searchInput.toLowerCase()) ? task : ''
      ))
      newTask.tasks.data = data
      return newTask
    })
    setTasksByCategories(filteredRows)
  }, [searchInput])

  useEffect(() => {
    tasksByCategories?.map((cat) => (
      cat.tasks.data.map((task) => (
        task.unit = tasks?.filter((taskParent) => taskParent.label === task.label)[0]?.measure.unit
      ))
    ))
    setRows(tasksByCategories)
  }, [tasksByCategories])

  const getTasksByCat = () => {
    callApi({
      method: 'get',
      url: `admin/construction-sites/${chantier.id}/tasks`
    }).then(res => {
      if (!res) return
      setTasksByCategories(res.data.data)
    })
  }

  const getCategories = () => {
    callApi({
      method: 'get',
      url: `admin/construction-sites/${chantier.id}/chapters`
    }).then(res => {
      if (!res) return
      setCategory(res.data.data)
    })
  }

  const getTasks = () => {
    callApi({
      method: 'get',
      url: 'admin/tasks'
    }).then(res => {
      if (!res) return
      setTasks(res.data.data)
    })
  }

  const deleteTask = async () => {
    callApi({
      method: 'delete',
      url: `admin/construction-sites/${chantier.id}/tasks/${taskSelected.id}?removeClockingsEntryTasks=${!removeVentilation ? 0 : 1}`
    })
      .then(res => {
        if (!res) return

        toast({
          position: 'bottom-right',
          description: res?.data?.message || 'Tâche supprimée avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        getTasksByCat()
      })
  }

  const editTask = (task) => {
    callApi({
      method: 'put',
      url: `admin/construction-sites/${chantier.id}/tasks/${task.id}`,
      data: {
        in_progress: !task.in_progress
      }
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.message || 'Tâche modifiée avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        getTasksByCat()
      })
  }

  const toogleSubrows = (id) => {
    const copy = [...toogleShow]
    const index = copy.indexOf(id)
    copy.includes(id) ? copy.splice(index, 1) : copy.push(id)
    setShowToogle(copy)
  }

  return (
    <TabPanel>
      <HStack mb='2'>
        <Text fontSize='22px' fontWeight='200'>Tâches</Text>
        <Button style={{ display: 'none' }} leftIcon={<CiImport />} data-variant='solid'> Importer </Button>
        <Button style={{ display: 'none' }} leftIcon={<CiExport />} data-variant='outline'> Exporter </Button>
      </HStack>
      <Card px='2' py='6' mr='0'>
        <HStack>
          <Box style={{ width: '50%', alignSelf: 'flex-start' }}>
            <Text fontSize='22px' px='5' mb='2'>Initiales</Text>
            <Card padding='20px'>
              <HStack>
              </HStack>
              <TableContainer>
                <Table>
                  <Thead>
                    <Tr>
                      <Th className='rowSearch' style={{ textTransform: 'none', fontFamily: 'Roboto', letterSpacing: '0.00938em', color: 'var(--chakra-colors-chakra-body-text)' }}
                      >
                      <Input name='Rechercher...' value={searchQuery} setValue={setSearchQuery}/>
                      </Th>
                      <Th> Prévues </Th>
                      <Th> Réalisés </Th>
                      <Th> Statut </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                  {rows && rows.map((cat) => {
                    return (
                      <>
                      <Tr key={cat.id}>
                      <Td>
                        <HStack>
                          <Icon style={{ cursor: 'pointer' }} as={SmallAddIcon}
                          onClick={(e) => {
                            e.stopPropagation()
                            toogleSubrows(cat.id)
                          }}
                          >
                          </Icon>
                          <Text fontSize='sm'>{cat.title}</Text>
                          <Button size='xs'>
                          <Icon as={SmallAddIcon} onClick={(e) => {
                            e.stopPropagation()
                            setTaskSelected()
                            setCategorySel(cat)
                            setModalEditOpen(true)
                          }} />
                          </Button>
                        </HStack>
                      </Td>
                      </Tr>
                      {cat.tasks.data.map((task) => (
                        ((task.in_progress === 0 || !task.in_progress)) && toogleShow.includes(cat.id) &&
                          <Tr key={task.id}>
                          <Td size='sm' style={{ padding: '1rem' }}>
                            <HStack>
                              <Button size='xs' variant='ghost' aria-label='Edit task' className='icon-btn' onClick={(e) => {
                                e.stopPropagation()
                                setCategorySel(cat)
                                setTaskSelected(task)
                                setModalEditOpen(true)
                              }}>
                              <Icon as={FiEdit2} fontSize='1rem'></Icon>
                              </Button>
                              <Button size='xs' variant="ghost" aria-label="Delete task" className='icon-btn' onClick={(e) => {
                                e.stopPropagation()
                                setTaskSelected(task)
                                setModalDeleteOpen(true)
                              }} m='0' p='0'>
                                <Icon as={FiTrash2} fontSize="1.25rem" />
                              </Button>
                              {task.label !== null
                                ? <Text style={{ cursor: 'pointer' }} fontSize='xs' onClick={(e) => {
                                  e.stopPropagation()
                                  setTaskSelected(task)
                                  setOpenModalHistorical(true)
                                }}>{task.label}</Text>
                                : <><Icon as={WarningTwoIcon} fontSize="1rem" />
                                <Text fontSize='xs'> La tâche parente à été supprimée
                                </Text>
                              </>
                              }
                            </HStack>
                          </Td>
                          <Td>
                            <HStack justifyContent={'center'} style={{ backgroundColor: '#f5f5f5', borderRadius: '0.375rem' }} py='3'>
                              <Text fontSize='xs'>{task.quantity_planned} {task.unit || ''} - </Text>
                              <Text fontSize='xs'>{task.hours_planned}H</Text>
                            </HStack>
                          </Td>
                          <Td>
                          <HStack justifyContent={'center'} style={{ backgroundColor: '#f5f5f5', borderRadius: '0.375rem' }} py='3'>
                              <Text fontSize='xs'>{task.quantity_worked} {task.unit || ''}-</Text>
                              <Text fontSize='xs'>{task.hours_worked}H</Text>
                            </HStack>
                            </Td>
                          <Td>
                            <HStack>
                            <Text color="muted" className='container-text'>
                                <span style={{ minWidth: '10px', minHeight: '10px', background: getStatusColors(task.in_progress), borderRadius: '50%', marginRight: '5px', display: 'inline-block', cursor: 'default' }} />
                              </Text>
                              {task.in_progress === 0 || !task.in_progress ? <Text fontSize='xs'> Pas commencer </Text> : <Text fontSize='xs'> En cours</Text>}
                              <Checkbox
                                onChange={() => editTask(task)}
                              ></Checkbox>
                            </HStack>
                            </Td>
                        </Tr>
                      ))}
                      </>
                    )
                  })}
                  </Tbody>
                </Table>
              </TableContainer>
            </Card>
          </Box>
          <Box style={{ width: '50%', alignSelf: 'flex-start' }} mr='2'>
            <Text fontSize='22px' px='5' mb='2'>En cours</Text>
            <Card padding='20px'>
              <TableContainer>
                <Table>
                  <Thead>
                    <Tr>
                      <Th className='rowSearch' style={{ textTransform: 'none', fontFamily: 'Roboto', letterSpacing: '0.00938em', color: 'var(--chakra-colors-chakra-body-text)', top: '15px' }}>
                        <Input name='Rechercher...' value={searchInput} setValue={setSearchInput}/> </Th>
                      <Th> Prévues </Th>
                      <Th> Réalisés </Th>
                      <Th> Statut </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                  {rows && rows.map((cat) => {
                    return (
                      <>
                      <Tr key={cat.id}>
                      <Td>
                        <HStack>
                          <Text fontSize='sm'>{cat.title}</Text>
                        </HStack>
                      </Td>
                      </Tr>
                      {cat.tasks.data.map((task) => (
                        (task.in_progress === 1 || task.in_progress === true) &&
                        <Tr key={task.id}>
                          <Td style={{ width: '25%', padding: '1rem' }}>
                            <HStack>
                              <Button size='xs' variant='ghost' aria-label='Edit task' className='icon-btn' onClick={(e) => {
                                e.stopPropagation()
                                setCategorySel(cat)
                                setTaskSelected(task)
                                setModalEditOpen(true)
                              }}>
                              <Icon as={FiEdit2} fontSize='1rem'></Icon>
                              </Button>
                              <Button size='xs' variant="ghost" aria-label="Delete task" className='icon-btn' onClick={(e) => {
                                e.stopPropagation()
                                setTaskSelected(task)
                                setModalDeleteOpen(true)
                              }} m='0' p='0'>
                                <Icon as={FiTrash2} fontSize="1.25rem" />
                              </Button>
                              {task.label !== null
                                ? <Text style={{ cursor: 'pointer' }} fontSize='xs' onClick={(e) => {
                                  e.stopPropagation()
                                  setTaskSelected(task)
                                  setOpenModalHistorical(true)
                                }}>{task.label}</Text>
                                : <><Icon as={WarningTwoIcon} fontSize="1rem" />
                                <Text fontSize='xs'> La tâche parente à été supprimée
                                </Text>
                              </>}
                            </HStack>
                          </Td>
                          <Td>
                            <HStack justifyContent={'center'} style={{ backgroundColor: '#f5f5f5', borderRadius: '0.375rem' }} py='3'>
                              <Text fontSize='xs'>{task.quantity_planned} {task.unit || ''} -</Text>
                              <Text fontSize='xs'>{task.hours_planned}H</Text>
                            </HStack>
                          </Td>
                          <Td>
                          <HStack justifyContent={'center'} style={{ backgroundColor: '#f5f5f5', borderRadius: '0.375rem' }} py='3'>
                              <Text fontSize='xs'>{task.quantity_worked} {task.unit || ''}-</Text>
                              <Text fontSize='xs'>{task.hours_worked}H</Text>
                            </HStack>
                            </Td>
                          <Td>
                            <HStack>
                            <Text color="muted" className='container-text'>
                                <span style={{ minWidth: '10px', minHeight: '10px', background: getStatusColors(task.in_progress), borderRadius: '50%', marginRight: '5px', display: 'inline-block', cursor: 'default' }} />
                              </Text>
                              {task.in_progress === 0 ? <Text fontSize='xs'> Pas commencer</Text> : <Text fontSize='xs'> En cours</Text>}
                              <Checkbox
                                defaultChecked
                                onChange={() => editTask(task)}
                              ></Checkbox>
                            </HStack>
                            </Td>
                        </Tr>
                      ))}
                      </>
                    )
                  })}
                  </Tbody>
                </Table>
              </TableContainer>
            </Card>
          </Box>
          </HStack>
          <HStack p='3'>
              <Button leftIcon={<AddIcon fontSize='lg'/>} style={{ color: '#ff5464', backgroundColor: 'white', fontWeight: '500' }}
              onClick={() => setModalOpenCategory(true)}>
                Créer un titre de catégorie
              </Button>
              <Button data-variant='solid' onClick={() => setModalOpen(true)}>
                Créer une nouvelle tâche
              </Button>
            </HStack>
      </Card>
      {modalOpen &&
        <AddTask
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          reloadData={getTasks}
          measuresList={measures}
        />
      }
      {
        modalEditOpen &&
        <EditTask
          open={modalEditOpen}
          onClose={() => setModalEditOpen(false)}
          taskSelected={taskSelected}
          categorySelected={categorySel}
          categoriesList={categoriesOptions}
          tasksList={tasksOptions}
          chantier={chantier.id}
          reloadData={getTasksByCat}
          tasks={tasks}
        />
      }
      {modalDeleteOpen && (
      <ConfirmDelete
        open={modalDeleteOpen}
        onClose={() => setModalDeleteOpen(false)}
        confirm={deleteTask}
        cancel={() => setModalDeleteOpen(false)}
        items={[taskSelected]}
        label={'Tâche'}
        customMessage='⚠️ Attention, en supprimant la tâche de ce chantier, cela supprimera la ventilation affectée'
        customPostMessage={<Box display='flex' flexDirection="row">
          <CheckboxGroup>
            <Checkbox
              onChange={() => {
                setRemoveVentilation(prev => !prev)
              }}
              isChecked={removeVentilation}
            />
            <Text ml={2}>Forcer la suppression des ventilations associées</Text>
          </CheckboxGroup>
        </Box>}
        />
      )}
      <AddCategory
        open={modalOpenCategory}
        onClose={() => setModalOpenCategory(false)}
        chantier={chantier}
        getTaskByCategories={getTasksByCat}
      />
      {modalHistoricalOpen &&
      <HistoricalModal
        open={modalHistoricalOpen}
        onClose={() => setOpenModalHistorical(false)}
        chantierId={chantier.id}
        taskSelected={taskSelected}
      />
      }
    </TabPanel>
  )
}

export default Interventions
