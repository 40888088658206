import { useState, useEffect } from 'react'

import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, ModalFooter, Stack, ButtonGroup, Text, Box } from '@chakra-ui/react'

import { Select, DateTimeInput } from 'components'

import { useDatas, useAuth } from 'contexts'
import { generateExcelFile, downloadExcelFile } from 'helpers/excel'

import moment from 'moment'

const planningTypesOptions = [
  { value: 'ouvrier', label: 'Par ouvrier' },
  { value: 'chantier', label: 'Par chantier' },
  { value: 'vehicule', label: 'Par véhicule' }
]

const durationOptions = [
  { value: 'week', label: 'semaine' },
  { value: 'month', label: 'mois' }
]

const generateOuvrierExcel = (datas, startDate, endDate) => {
  const workbook = generateExcelFile()
  const worksheet = workbook.addWorksheet('Par Ouvrier')
  const worksheetColumns = [
    { header: 'Société', key: 'company', width: 30 },
    { header: 'Employé', key: 'employee', width: 32 },
    { header: 'Poste', key: 'position', width: 32 }
  ]
  const numberOfDefaultWorksheetColumns = worksheetColumns.length
  const mEndDate = moment(endDate)
  for (let mStartDate = moment(startDate); mStartDate.isSameOrBefore(mEndDate); mStartDate.add(1, 'day')) {
    worksheetColumns.push({
      header: mStartDate.format('DD/MM'),
      key: mStartDate.unix(),
      width: 10
    })
  }
  worksheet.columns = worksheetColumns
  worksheet.getColumn(1).font = { bold: true }
  worksheet.getColumn(2).font = { bold: true }
  worksheet.getRow(1).font = { size: 12, bold: true }
  datas
    .filter(data => data.timesheetSchedules?.length)
    .forEach(data => {
      data.timesheetSchedules
        .filter(timesheet => moment(timesheet.endFull).isSameOrAfter(moment(startDate).add(1, 'hour')))
        .forEach(timesheet => {
          const rowValues = []
          rowValues[0] = data.company.name
          rowValues[1] = data.fullName
          rowValues[2] = data.position
          const timesheetStartDate = moment(timesheet.startDate)
          const timesheetEndDate = moment(timesheet.endDate)
          let i = numberOfDefaultWorksheetColumns + 1
          const row = worksheet.addRow(rowValues)
          for (let mStartDate = moment(startDate); mStartDate.isSameOrBefore(mEndDate); mStartDate.add(1, 'day')) {
            if (mStartDate.isSameOrAfter(timesheetStartDate) && mStartDate.isSameOrBefore(timesheetEndDate)) {
              row.getCell(i).value = timesheet.constructionSite.name
              row.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: (timesheet.constructionSite?.color ?? '#ff5464').replace('#', '') }
              }
              // rowValues[i] = timesheet.constructionSite.name
            }
            i++
          }
        })
    })
  // worksheet.getCell('A2').fill = {
  //   type: 'pattern',
  //   pattern: 'solid',
  //   fgColor: { argb: 'F08080' }
  // }
  downloadExcelFile(workbook, `planning-ouvrier-du-${moment(startDate).format('DD-MM-YYYY')}-au-${mEndDate.format('DD-MM-YYYY')}`)
}

const generateChantierExcel = (datas, startDate, endDate) => {
  const workbook = generateExcelFile()
  const worksheet = workbook.addWorksheet('Par Chantier')
  const worksheetColumns = [
    { header: 'Chantier', key: 'constructionSite', width: 30 }
  ]
  const numberOfDefaultWorksheetColumns = worksheetColumns.length
  const mEndDate = moment(endDate)
  for (let mStartDate = moment(startDate); mStartDate.isSameOrBefore(mEndDate); mStartDate.add(1, 'day')) {
    worksheetColumns.push({
      header: mStartDate.format('DD/MM'),
      key: mStartDate.unix(),
      width: 10
    })
  }
  worksheet.columns = worksheetColumns
  worksheet.getColumn(1).font = { bold: true }
  worksheet.getRow(1).font = { size: 12, bold: true }
  const rows = []
  datas
    .filter(data => data.timesheetSchedules?.length)
    .forEach(data => {
      data.timesheetSchedules
        .filter(timesheet => moment(timesheet.endFull).isSameOrAfter(moment(startDate).add(1, 'hour')))
        .forEach(timesheet => {
          const rowValues = []
          rowValues[0] = timesheet.constructionSite.name
          const timesheetStartDate = moment(timesheet.startDate)
          const timesheetEndDate = moment(timesheet.endDate)
          let i = numberOfDefaultWorksheetColumns
          for (let mStartDate = moment(startDate); mStartDate.isSameOrBefore(mEndDate); mStartDate.add(1, 'day')) {
            if (mStartDate.isSameOrAfter(timesheetStartDate) && mStartDate.isSameOrBefore(timesheetEndDate)) {
              rowValues[i] = `${data.fullName} - ${data.company.name}`
            }
            i++
          }
          rows.push(rowValues)
        })
    })
  rows
    .sort((row, nextRow) => {
      const textA = row[0].toLowerCase()
      const textB = nextRow[0].toLowerCase()
      return textA < textB ? -1 : textA > textB ? 1 : 0
    })
    .forEach(row => {
      worksheet.addRow(row)
    })
  // worksheet.getCell('A2').fill = {
  //   type: 'pattern',
  //   pattern: 'solid',
  //   fgColor: { argb: 'F08080' }
  // }
  downloadExcelFile(workbook, `planning-chantier-du-${moment(startDate).format('DD-MM-YYYY')}-au-${mEndDate.format('DD-MM-YYYY')}`)
}

const generateVehiculeExcel = (datas, startDate) => {
  const workbook = generateExcelFile()
  const worksheet = workbook.addWorksheet('Par Chantier')
  const worksheetColumns = [
    { header: 'Chantier', key: 'constructionSite', width: 30 },
    { header: 'Véhicule', key: 'vehicule', width: 30 },
    { header: 'Employé', key: 'employee', width: 30 }
  ]
  worksheet.columns = worksheetColumns
  worksheet.getColumn(1).font = { bold: true }
  worksheet.getRow(1).font = { size: 12, bold: true }
  datas
    .filter(constructionSite => constructionSite.noVehiculesEmployees.length + constructionSite.vehicules.length > 0)
    .forEach(constructionSite => {
      constructionSite.vehicules.forEach(vehicule => {
        if (vehicule.employees.length) {
          vehicule.employees.forEach(employee => {
            const rowValues = [constructionSite.name, `${vehicule.vehicule.brand} - ${vehicule.vehicule.immatriculation}`, `${employee.employee.name} - ${employee.company.name}`]
            worksheet.addRow(rowValues)
          })
        } else {
          const rowValues = [constructionSite.name, `${vehicule.vehicule.brand} - ${vehicule.vehicule.immatriculation}`, '-']
          worksheet.addRow(rowValues)
        }
      })
      constructionSite.noVehiculesEmployees.forEach(employee => {
        const rowValues = [constructionSite.name, '-', `${employee.employee.name} - ${employee.company.name}`]
        worksheet.addRow(rowValues)
      })
    })
  downloadExcelFile(workbook, `planning-vehicule-${moment(startDate).format('DD-MM-YYYY')}`)
}

const Export = () => {
  const { callApi } = useAuth()
  const {
    datas: {
      companies: companiesOptions,
      chantiers: chantierOptions
      // employees: employeesOptions
    }
  } = useDatas()

  const [open, setOpen] = useState(false)
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const submitOuvrier = () => {
    callApi({
      method: 'get',
      url: `admin/timesheet/companies?search=&start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(endDate).format('YYYY-MM-DD')}&filter=`
    })
      .then(res => {
        if (!res?.data?.data) return
        const datasCompanies = res?.data?.data
        callApi({
          method: 'get',
          url: `admin/timesheet/companies/schedules?search=&start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(endDate).format('YYYY-MM-DD')}&filter=${chantier?.value ?? ''}${(companies?.length || companiesOptions).map(e => `&companies[]=${e.value}`).join('')}`
        })
          .then(res => {
            if (!res?.data?.data) return
            const employees = res?.data?.data
              .map(employee => ({
                ...employee,
                company: datasCompanies.filter(c => (c.employees || []).map(e => e?.id).includes(employee.id))[0]
              }))
            generateOuvrierExcel(employees, startDate, endDate)
          })
          .finally(() => setIsSubmitting(false))
      })
  }

  const submitChantier = () => {
    callApi({
      method: 'get',
      url: `admin/timesheet/companies?search=&start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(endDate).format('YYYY-MM-DD')}&filter=`
    })
      .then(res => {
        if (!res?.data?.data) return
        const datasCompanies = res?.data?.data
        callApi({
          method: 'get',
          url: `admin/timesheet/companies/schedules?search=&start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(endDate).format('YYYY-MM-DD')}&filter=${chantier?.value ?? ''}${(companies?.length || companiesOptions).map(e => `&companies[]=${e.value}`).join('')}`
        })
          .then(res => {
            if (!res?.data?.data) return
            const employees = res?.data?.data
              .map(employee => ({
                ...employee,
                company: datasCompanies.filter(c => (c.employees || []).map(e => e?.id).includes(employee.id))[0]
              }))
            generateChantierExcel(employees, startDate, endDate)
          })
          .finally(() => setIsSubmitting(false))
      })
  }

  const submitVehicule = () => {
    callApi({
      method: 'get',
      url: `admin/timesheet/vehicle?search=&date=${moment(startDate).format('YYYY-MM-DD')}`
    })
      .then(res => {
        if (!res) return
        const { chantiers = [], vehicules = [], employees = [] } = res?.data?.data
        const vehiculesEmployeesIds = vehicules.map(v => v.employeeId)
        const newDatas = chantiers.map(chantier => {
          const chantierVehicules = []
          const chantierEmployeesWithVehicule = employees.filter(({ employeeId, constructionSiteId }) => constructionSiteId === chantier.id && vehiculesEmployeesIds.includes(employeeId))
          const chantierEmployeesWithoutVehicule = employees.filter(({ employeeId, constructionSiteId }) => constructionSiteId === chantier.id && !vehiculesEmployeesIds.includes(employeeId))

          vehicules.filter(({ constructionSiteId }) => constructionSiteId === chantier.id).forEach(vehicule => {
            if (!chantierVehicules[vehicule.timesheetVehiculeId]) {
              chantierVehicules[vehicule.timesheetVehiculeId] = {
                ...vehicule,
                employees: []
              }
            }
            const toAdd = chantierEmployeesWithVehicule.filter(({ employeeId }) => employeeId === vehicule.employeeId)[0]
            if (toAdd) {
              if (vehicule.employeeIsConductor) {
                chantierVehicules[vehicule.timesheetVehiculeId].employees.unshift(toAdd)
              } else {
                chantierVehicules[vehicule.timesheetVehiculeId].employees.push(toAdd)
              }
            }
          })

          return {
            ...chantier,
            vehicules: chantierVehicules.filter(cv => cv),
            noVehiculesEmployees: chantierEmployeesWithoutVehicule
          }
        })
        generateVehiculeExcel(newDatas, startDate)
      })
      .finally(() => setIsSubmitting(false))
  }

  const submit = () => {
    setIsSubmitting(true)
    if (planningType === 'ouvrier') submitOuvrier()
    if (planningType === 'chantier') submitChantier()
    if (planningType === 'vehicule') submitVehicule()
  }

  const [planningType, setPlanningType] = useState(planningTypesOptions[0].value)

  const [duration, setDuration] = useState(durationOptions[0])
  const [startDate, setStartDate] = useState(moment().startOf('isoWeek'))
  const [endDate, setEndDate] = useState(null)

  const [chantier, setChantier] = useState()
  // const [employees, setEmployees] = useState([])
  const [companies, setCompanies] = useState([])

  useEffect(() => {
    if (startDate) {
      setEndDate(moment(startDate).endOf(duration.value === 'week' ? 'isoWeek' : 'month'))
    }
  }, [startDate])

  useEffect(() => {
    if (startDate) {
      setStartDate(moment(startDate).startOf(duration.value === 'week' ? 'isoWeek' : 'month'))
      setEndDate(moment(startDate).endOf(duration.value === 'week' ? 'isoWeek' : 'month'))
    }
  }, [duration, planningType])

  return <>
    <Button data-variant='solid' onClick={onOpenModal}>Exporter</Button>
    <Modal blockScrollOnMount={true} scrollBehavior='inside' size='2xl' isOpen={open} onClose={onCloseModal}>
      <ModalOverlay />
      <ModalContent w={{ base: '95%', md: '50%', lg: '35%' }}>
        <ModalHeader>
          <Text fontSize={24}>Exporter les plannings</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Text fontSize={14} mb='1.5rem'>Export par planning ouvrier, chantier, véhicule en sélectionnant la période visuelle et les filtres associés ci-dessous :</Text>
          <Stack spacing={5} my='5' pointerEvents={isSubmitting ? 'none' : ''}>
            <ButtonGroup size='md' isAttached variant='outline' mb='2'>
              {planningTypesOptions?.map(type => <Button
                key={type.value}
                style={{ fontSize: '0.8rem', fontWeight: 'normal', backgroundColor: planningType === type.value ? '#eee' : '', width: '100%' }}
                onClick={() => setPlanningType(type.value)}>
                  {type.label}
                </Button>
              )}
            </ButtonGroup>
            {planningType === 'vehicule'
              ? <DateTimeInput name='Date à exporter' value={startDate} setValue={setStartDate} />
              : <>
                  <Select name={'Exporter par'} value={duration} setValue={setDuration} options={durationOptions} />
                  <Box style={duration.value === 'week' ? { display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-around' } : { display: 'none' }}>
                    <DateTimeInput name='Du' value={startDate} setValue={setStartDate} shouldDisableDate={(date) => date.day() !== 1} />
                    <DateTimeInput name='Au' value={endDate} setValue={setEndDate} minDate={startDate} shouldDisableDate={(date) => date.day() !== 0} />
                  </Box>
                  <Box style={{ display: duration.value === 'month' ? 'initial' : 'none' }}>
                    <DateTimeInput name='Mois à exporter' format='MMMM' value={startDate} setValue={setStartDate} views={['year', 'month']} openTo="month" />
                  </Box>
                </>
            }
            {planningType !== 'vehicule' && <Select name='Chantier concerné' value={chantier} setValue={setChantier} options={chantierOptions} isClearable={true} />}
            {/* <Select name='Ouvriers' value={employees} setValue={setEmployees} options={employeesOptions} isMulti /> */}
            {planningType !== 'vehicule' && <Select name='Société concernée' value={companies} setValue={setCompanies} options={companiesOptions} isMulti isClearable={true} />}
          </Stack>
        </ModalBody>
        <ModalFooter zIndex={12} justifyContent='center' borderTopWidth='1px'>
          <Button isLoading={isSubmitting} data-variant='solid' onClick={submit}>Exporter</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>
}

export default Export
