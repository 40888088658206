/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Text,
  Stack,
  useToast,
  Checkbox, CheckboxGroup,
  TableContainer,
  Table,
  Th,
  Thead,
  Tbody,
  Tr,
  Td,
  HStack,
  Icon, InputRightAddon, InputGroup
} from '@chakra-ui/react'
import { /* FiMinus, */ FiTrash2 } from 'react-icons/fi'
import { SmallAddIcon } from '@chakra-ui/icons'

import { DateTimeInput, Input, Select } from 'components'

import { useAuth, useDatas, useModals } from 'contexts'

import moment from 'moment'

function convertToMin (time) {
  const [hours, minutes] = time.split(':')
  return Number(hours) * 60 + Number(minutes)
}

const UpdateClocking = ({ open, onClose, clocking, callback }) => {
  const { callApi } = useAuth()
  const { datas: { absences, chantiers, tasks } } = useDatas()
  const toast = useToast()
  const { openConfirmToDeleteModal } = useModals()

  const [absence, setAbsence] = useState(clocking.absenceId ? (absences.filter(absence => parseInt(clocking.absenceId) === absence.value)[0] ?? { value: clocking.absenceId, label: '', global: true, app_availability: true }) : '')
  const [comment, setComment] = useState(clocking.comment)
  const [packedLunch, setPackedLunch] = useState(clocking.packedLunch)
  const [personalTransport, setPersonalTransport] = useState(clocking.personalTransport)
  const [time, setTime] = useState(null)
  const [tasksByCategories, setTasksByCategories] = useState([])
  const [toogleShow, setShowToogle] = useState([])

  useEffect(() => {
    setTime(clocking.durationMin ? moment(clocking.durationMin, [moment.ISO_8601, 'HH:mm']) : null)
  }, [clocking])

  useEffect(() => {
    const chantierSelected = chantiers.filter((chantier) => chantier.label === clocking.constructionSite)
    if (chantierSelected.length > 0) {
      callApi({
        method: 'get',
        url: `admin/construction-sites/${chantierSelected[0].value}/tasks`
      })
        .then((res) => {
          if (!res) return

          const temp = []

          res?.data?.data.filter(taskCat => taskCat.tasks.data.length > 0).forEach((taskCat) => {
            taskCat.tasks.data = taskCat.tasks.data
              .filter(taskCatTask => taskCatTask.in_progress || clocking.clockingEntryTasks.filter(clockingEntryTask => clockingEntryTask.task.id === taskCatTask.id))
              .map((taskCatTask) => {
                const clockingEntryTask = clocking.clockingEntryTasks.filter(clockingEntryTask => clockingEntryTask.task.id === taskCatTask.id)

                if (clockingEntryTask.length > 0) {
                  taskCatTask.quantity_clocked = clockingEntryTask[0].quantity !== 0 ? clockingEntryTask[0].quantity : ''
                  taskCatTask.duration = clockingEntryTask[0].duration !== 0 ? (Math.floor((clockingEntryTask[0].duration ?? 0) / 60) + '.' + (clockingEntryTask[0].duration ?? 0) % 60) : ''
                }

                return taskCatTask
              })

            temp.push(taskCat)
          })

          setTasksByCategories(temp)
        })
    }
  }, [clocking.clockingEntryTasks])

  const handleQuantity = (task, value) => {
    const taskUpdated = [...tasksByCategories]
    taskUpdated.map((cat) => (
      cat.tasks.data.map((taskToUpdate) => (
        taskToUpdate.quantity_clocked = taskToUpdate.id === task.id ? Number(value) : taskToUpdate.quantity_clocked
      ))
    ))
    setTasksByCategories(taskUpdated)
  }

  const handleHours = (task, value) => {
    const taskUpdated = [...tasksByCategories]

    taskUpdated.map((cat) => (
      cat.tasks.data.map((taskToUpdate) => (
        taskToUpdate.duration = taskToUpdate.id === task.id ? Number(value) : taskToUpdate.duration
      ))
    ))

    setTasksByCategories(taskUpdated)
  }

  const toogleSubrows = (id) => {
    const copy = [...toogleShow]
    const index = copy.indexOf(id)
    copy.includes(id) ? copy.splice(index, 1) : copy.push(id)
    setShowToogle(copy)
  }

  const submit = () => {
    const taskUpdated = []

    tasksByCategories.map((cat) => cat.tasks.data.map((task) => {
      let finalValue = task.duration ? task.duration : 0

      const minutesPart = finalValue != null && finalValue % 1 !== 0 ? finalValue.toString().split('.')[1] : null
      let minutesFinales = null
      if (minutesPart != null && minutesPart.length === 1) {
        minutesFinales = parseInt(minutesPart + '0')
      } else if (minutesPart != null) {
        minutesFinales = parseInt(minutesPart)
      }

      if (minutesFinales != null && minutesFinales > 59) {
        finalValue = null
      }

      return taskUpdated.push({
        id: task.id,
        duration: Math.floor(finalValue) * 60 + minutesFinales,
        quantity_worked: task.quantity_clocked ? task.quantity_clocked : 0
      })
    }))
    callApi({
      method: 'patch',
      url: `admin/clocking/${clocking.clockingId}`,
      data: {
        timesheet_absence_id: absence ? absence.value : null,
        comment,
        duration: convertToMin(moment(time).format('HH:mm')),
        packed_lunch: packedLunch,
        personal_transport: personalTransport,
        tasks: taskUpdated.length > 0 ? taskUpdated : null
      }
    })
      .then(res => {
        if (!res) return
        callback(res)
        toast({
          position: 'bottom-right',
          description: res?.data?.message || clocking?.isValidated ? 'Cette feuille a bien été invalidé' : 'Cette feuille a bien été validé',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        onClose()
      })
  }

  const mdelete = () => {
    callApi({
      method: 'delete',
      url: `admin/clocking/${clocking.clockingId}`
    })
      .then(res => {
        if (!res) return
        callback(res)
        toast({
          position: 'bottom-right',
          description: 'Supprimé avec success',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        onClose()
      })
  }

  const getActualClockingEntryTask = (taskId) => {
    const clockingEntryTasks = clocking.clockingEntryTasks.filter((clockingEntryTask) => clockingEntryTask.task.id === taskId)

    return clockingEntryTasks.length > 0 ? clockingEntryTasks[0] : null
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '70%',
        lg: '50%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>Modifier ce pointage</Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text fontSize={14} mb='1.5rem'>{"Modifier le nombre d'heures travaillées dans le champ ci-dessous :"}</Text>
        {absence && (<Stack spacing={1} my='5'>
          <Select name="Motif d'absence" value={absence} setValue={setAbsence} options={absences} />
        </Stack>)}
        <Stack spacing={1} my='5'>
          <DateTimeInput name='Durée' value={time} setValue={setTime} time={true} date={false} minutesStep='5' />
        </Stack>
        {clocking.employee.clockingEntries[0].clocking_entry_tasks && (
          <Stack style={{ display: 'block', maxHeight: '400px', overflow: 'scroll' }}>
            <TableContainer >
              <Table size='sm'>
                <Thead>
                  <Th></Th>
                  <Th></Th>
                  <Th></Th>
                </Thead>
                <Tbody>
                  {tasksByCategories && tasksByCategories.map((cat) => {
                    return (
                      <>
                    <Tr key={cat.id}>
                      <Td>
                        <HStack>
                          <Icon style={{ cursor: 'pointer' }} as={SmallAddIcon}
                          onClick={(e) => {
                            e.stopPropagation()
                            toogleSubrows(cat.id)
                          }}
                          >
                          </Icon>
                        <Text fontSize='sm'>{cat.title}</Text>
                        </HStack>
                      </Td>
                    </Tr>
                    {cat.tasks.data.map((task) => (
                      toogleShow.includes(cat.id) &&
                      <Tr key={task.id}>
                        <Td>
                          <Text style={{ textAlign: 'center' }} fontSize={14}>{task.label}</Text>
                        </Td>
                        <Td width='120px'>
                          <HStack style={{ backgroundColor: '#f5f5f5', borderRadius: 'var(--chakra-radii-md)' }}>
                            <Input
                              icon={task.measure.unit}
                              type='number'
                              min={0}
                              fontSize='xs'
                              value={getActualClockingEntryTask(task.id) ? (task.quantity ?? 0) : ''}
                              setValue={(val) => handleQuantity(task, val)}
                            />
                          </HStack>
                        </Td>
                        <Td width='120px'>
                          <HStack style={{ backgroundColor: '#f5f5f5', borderRadius: 'var(--chakra-radii-md)' }}>
                            <Input
                              type='number'
                              icon="H"
                              min={0}
                              value={getActualClockingEntryTask(task.id) ? (task.duration ?? 0) : ''}
                              setValue={(val) => handleHours(task, val)}
                            />
                          </HStack>
                        </Td>
                      </Tr>
                    ))}
                    </>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Stack>
        )}
        <Stack spacing={1} my='5'>
          coucou
          <Input name='Commentaires' value={comment ?? ''} setValue={setComment} />
        </Stack>
        <Stack spacing={1} my='5'>
          <CheckboxGroup>
            <Checkbox
              padding='5px 10px'
              _hover={{ color: '#fe5464' }}
              isChecked={packedLunch}
              onChange={() => setPackedLunch(!packedLunch)}
            >
              <Text>Panier repas</Text>
            </Checkbox>
          </CheckboxGroup>
          <CheckboxGroup>
            <Checkbox
              padding='5px 10px'
              _hover={{ color: '#fe5464' }}
              isChecked={personalTransport}
              onChange={() => setPersonalTransport(!personalTransport)}
            >
              <Text>Transport personnel</Text>
            </Checkbox>
          </CheckboxGroup>
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="space-around" borderTopWidth={'1px'}>
        <Button data-variant='solid' onClick={submit}>Modifier</Button>
        <Button data-variant='outline' onClick={onClose}>Annuler</Button>
        <Button data-variant='outline' onClick={() => openConfirmToDeleteModal({
          label: 'Pointage',
          items: [{ id: clocking.clockingId, label: `${clocking.clockingId} - ${clocking.durationMin}` }],
          confirm: mdelete
        })}><FiTrash2 /></Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default UpdateClocking
