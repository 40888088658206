import { useState, useEffect, useRef } from 'react'

import { Tooltip, Button, Box, Text, Avatar, HStack } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { AiOutlineEllipsis } from 'react-icons/ai'
import { BsMoonFill } from 'react-icons/bs'
import { GiMeal } from 'react-icons/gi'
import { MdDirectionsCarFilled, MdOutlineMarkChatRead } from 'react-icons/md'

import FullCalendar from '@fullcalendar/react'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import frLocale from '@fullcalendar/core/locales/fr'
import interaction from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'

import moment from 'moment'
import 'moment/locale/fr'

import Header from './components/Header'

import './clocking.scss'

import { Card } from 'components'

import { useAuth, useDatas } from 'contexts'

import { convertMinToHour } from 'helpers/utils'

import AddEmployee from 'pages/admin/Employees/components/modals/AddEmployee'
import AllClockingModal from './components/modals/AllClocking'

moment.locale('fr')

const Planning = () => {
  const calendarRef = useRef()
  const { callApi } = useAuth()
  const { datas: { clientSlug, hideWeekEnd } } = useDatas()
  const [loading, setLoading] = useState(true)
  const [employees, setEmployees] = useState([])
  const [clockings, setClockings] = useState([])

  const [search, setSearch] = useState('')
  const [chantier, setChantier] = useState({ id: 'all', label: 'Tous les chantiers' })

  const [startDate, setStartDate] = useState(moment().startOf('isoWeek').format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().endOf('isoWeek').weekday(hideWeekEnd ? 4 : 6).format('YYYY-MM-DD'))

  const [selectedEmployee, setSelectedEmployee] = useState()
  const [clocking, setClocking] = useState(false)

  const updateDate = (direction) => {
    const newStartDate = moment(startDate).add(direction, 'week').startOf('isoWeek').format('YYYY-MM-DD')
    const newEndDate = moment(newStartDate).endOf('isoWeek').format('YYYY-MM-DD')
    setStartDate(newStartDate)
    setEndDate(newEndDate)
    calendarRef.current.getApi().changeView('resourceTimeline', newStartDate)
  }

  const getClockings = () => {
    setLoading(true)
    setEmployees([])
    callApi({
      method: 'get',
      url: `admin/clocking?search=${search ?? ''}&start_date=${startDate}&end_date=${endDate}&construction_site_id=${chantier?.value === 'all' ? '' : chantier?.value ?? ''}`
    })
      .then(res => {
        if (!res) return
        const newEmployees = []
        const newClockings = [];
        // res?.data?.data?.forEach(employee => {
        (res?.data ?? []).forEach(employee => {
          const a = {
            id: employee.id,
            groupId: employee?.company?.name || null,
            title: employee.fullName,
            companyName: employee?.company?.name ?? '',
            extendedProps: {
              employee
            }
          }
          if (a.companyName.replace(' ', '').toLowerCase().includes(clientSlug)) {
            newEmployees.unshift(a)
          } else {
            newEmployees.push(a)
          }
          employee.clockingEntries && newClockings.unshift(...employee.clockingEntries.map(clockingEntry => {
            return {
              // start: clockingEntry?.clocking?.date,
              start: clockingEntry?.date,
              resourceId: employee?.id,
              id: clockingEntry?.id,
              title: convertMinToHour(clockingEntry?.duration, 'h:mm'),
              extendedProps: {
                employee,
                employeeId: employee?.id,
                employeeIsValidated: employee?.isEmployeeValidated,
                employeeFullName: employee?.fullName,
                employeePosition: employee?.employeePosition?.name,
                isNight: clockingEntry?.period === 'night',
                comment: clockingEntry?.comment,
                absenceId: clockingEntry?.absenceId,
                clockingId: clockingEntry?.id,
                durationStr: convertMinToHour(clockingEntry?.duration, 'str'),
                durationMin: convertMinToHour(clockingEntry?.duration, 'h:mm'),
                picture: employee?.picture,
                // labelAbsence: clockingEntry?.timesheetAbsence?.label,
                // constructionSite: clockingEntry?.clocking?.constructionSite?.name
                labelAbsence: clockingEntry?.absenceLabel,
                constructionSite: clockingEntry?.constructionSiteName ?? 'Aucun chantier',
                date: clockingEntry?.date,
                createdAt: clockingEntry?.createdAt,
                createdBy: clockingEntry?.createdBy,
                updatedAt: clockingEntry?.updatedAt,
                updatedBy: clockingEntry?.updatedBy,
                validatedAt: clockingEntry?.validatedAt,
                validatedBy: clockingEntry?.validatedBy,
                personalTransport: clockingEntry?.personalTransport,
                packedLunch: clockingEntry?.packedLunch,
                clockingEntryTasks: clockingEntry?.clocking_entry_tasks
              },
              period: clockingEntry?.period === 'night' ? 'De nuit' : 'De jour',
              isValidated: !!clockingEntry?.validatedAt,
              isNight: clockingEntry?.period === 'night',
              editable: false,
              resourceEditable: false,
              backgroundColor: 'transparent',
              borderColor: 'transparent',
              classNames: ['timesheet']
            }
          }).reverse())
        })
        setEmployees(newEmployees)
        setClockings(newClockings)
      })
      .finally(() => setLoading(false))
  }

  const timeoutRef = useRef()
  useEffect(() => {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(getClockings, 1000)
  }, [chantier, search, startDate])

  return <Card padding='0'>
		<Header
            chantier={chantier}
            setChantier={setChantier}
            search={search}
            setSearch={setSearch}
            loading={loading}
            startDate={startDate}
            endDate={endDate}
            callback={getClockings}
        />
		<FullCalendar
			ref={calendarRef}
			schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
			plugins={[resourceTimelinePlugin, momentPlugin, interaction]}
			locale={frLocale}
			height='800px'
			headerToolbar={false}
      hiddenDays={hideWeekEnd ? [6, 0] : []}
			slotMinWidth={108}
			editable={true}
			selectMirror={true}
			initialView='resourceTimeline'
			resourceGroupField='groupId' // Property inside 'resource' object used to display the name of the group
			resourceAreaWidth={301}
			resourceOrder='-registration_number'
			resources={employees}
			events={clockings}
			views={{
			  resourceTimeline: {
			    type: 'timeline',
			    duration: { weeks: 1 },
			    slotDuration: { days: 1 },
			    titleFormat: '[Semaine] W - YYYY'
			  }
			}}
			/* CURRENT WEEK (RIGHT) - FORMAT DD ddd. */
			slotLabelContent={(arg) => (<HStack height='70px' justify='center' align='center' flexDirection='column'>
				<Text fontSize='15px'>{ moment(arg.date).format('DD') }</Text>
				<Text ml='0!' fontSize='15px' fontWeight="light">{ moment(arg.date).format('ddd') }</Text>
			</HStack>)}
			/* CURRENT WEEK (LEFT) - FORMAT [Semaine] num - YYYY */
			resourceAreaHeaderContent={(arg) => (<Box style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', paddingTop: '10px' }} px='2'>
				<Button data-variant='outline' onClick={() => updateDate(-1)} style={{ padding: 0, borderRadius: '4px' }}>
					<ChevronLeftIcon size="22" />
				</Button>
				<Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '150px' }} color='#8186aa'>{ arg.view.title.slice(0, 17) }</Box>
				<Button data-variant='outline' onClick={() => updateDate(1)} style={{ padding: 0, borderRadius: '4px' }}>
					<ChevronRightIcon size="22" />
				</Button>
			</Box>)}
			/* GROUPE (NOM DE L'ENTREPRISE) */
			resourceGroupLabelContent={(arg) => (<div style={{ display: 'inline-flex', width: '100%', justifyContent: 'space-between', paddingRight: '33px', position: 'relative', top: '-1px' }}>
				<Text fontSize='14px' fontWeight="bold" color='#8186aa'>{ arg.groupValue }</Text>
				{/* arg.groupValue will take the value set for "resourceGroupField" */}
				<div className="line" />
			</div>)}
			/* LISTE EMPLOYEE */
			resourceLabelContent={(arg) => (<HStack px='2' pb='1' mt='-1' onClick={() => setSelectedEmployee(arg.resource.extendedProps.employee)} style={{ cursor: 'pointer' }}>
				<Avatar boxSize='8' size='xs' name={arg.fieldValue} src={arg.resource.extendedProps.employee?.picture ? arg.resource.extendedProps.employee.picture.uri : null} />
				<Box>
					<Text mt='0.5' fontSize='13px'>{arg.fieldValue}</Text>{/* arg.fieldValue will take the value set for "resources.title" */}
					<Text mt='-1' fontSize='11px'>{arg.resource.extendedProps.employee?.employeePosition ? arg.resource.extendedProps.employee.employeePosition.name : '-'}</Text>
				</Box>
			</HStack>)}
			eventContent={(arg) => (<Tooltip placement='top' label={String(arg.event.extendedProps.constructionSite)} color='white'>
				<Button
					onClick={() => setClocking(arg.event.extendedProps.employeeIsValidated ? arg.event.extendedProps : null)}
					style={{
					  width: '100%',
					  background: (arg.event.extendedProps.employeeIsValidated && arg.event.extendedProps.isValidated) ? '#bbecda' : arg.event.extendedProps.labelAbsence ? '#fddba0' : '#f5f5f5',
					  border: arg.event.extendedProps.employeeIsValidated ? 'none' : '1px solid #ff5464',
					  color: '#000'
					}}
				>
					<HStack justify="space-between">
						{arg.event.extendedProps.labelAbsence
						  ? <Text>{arg.event.extendedProps.labelAbsence}</Text>
						  : <HStack>
									<Text>{ arg.event.title }</Text>
									{arg.event.extendedProps.isNight && <BsMoonFill size='10' />}
								</HStack>
						}
						<AiOutlineEllipsis size='30' />
            {arg.event.extendedProps.comment?.length && <MdOutlineMarkChatRead />}
            {arg.event.extendedProps.personalTransport && <MdDirectionsCarFilled size={14} />}
            {arg.event.extendedProps.packedLunch && <GiMeal size={14} />}
					</HStack>
				</Button>
			</Tooltip>)}
		/>
		<AddEmployee open={Boolean(selectedEmployee)} onClose={() => setSelectedEmployee(null)} employee={!selectedEmployee
		  ? null
		  : {
		      ...selectedEmployee,
		      registrationNumber: selectedEmployee.registration_number,
		      firstName: selectedEmployee.first_name,
		      lastName: selectedEmployee.last_name,
		      phone: selectedEmployee.phone_number,
		      birthDate: selectedEmployee.birth_date,
		      hourlyRate: selectedEmployee.hourly_rate,
		      transportAmount: selectedEmployee.hourly_rate
		    }} viewOnly />
		<AllClockingModal open={Boolean(clocking)} onClose={() => setClocking(null)} clocking={clocking} callback={getClockings} />
	</Card>
}

export default Planning
